(function () {
    'use strict';

    angular.module('customerVault.wurfljs')
        .factory('wurfljsResolve', wurfljsResolve);

    wurfljsResolve.$inject = [
        '$filter',
        '$ocLazyLoad',
        'homeService',
        'impersonateService',
        'loginService',
        'wurfljsService',
        'wurfljsGoService',
        'CHARGIFY_JS_URL'
    ];

    function wurfljsResolve(
        $filter,
        $ocLazyLoad,
        homeService,
        impersonateService,
        loginService,
        wurfljsService,
        wurfljsGoService,
        CHARGIFY_JS_URL
    ) {
        var service = {
            error: {},
            load: {
                main: {}
            },
            mainError: {},
            run: run
        };

        return service;

        function run(args) {
            service.error = {};
            service.mainError = {};

            if (loginService.impersonate.mode) {
                // Check if impersonate user is admin
                return impersonateService.getCheckImpersonateIsAdmin(loginService.impersonate.user.id, 'subscription', args.params.id).then(function () {
                    return runIndex(args);
                }).catch(function (error) {
                    service.mainError.status = error.status;
                    service.mainError.message = error.data.message;
                    return runIndex(args);
                });
            }

            return runIndex(args);
        }

        function runIndex(args) {
            if (
                (_.isEmpty(service.load.main) || service.load.main.id != args.params.id) &&
                !_.isUndefined(args.params.id)
            ) {
                service.load.main = {};
                service.load.main.id = args.params.id;
                return wurfljsService.getAccount(args.params.id).then(function (response) {
                    if (!_.isUndefined(response.data.statusWrapper)) {
                        service.load.main.statusWrapper = 'view/subscriptions.' + response.data.statusWrapper;
                        service.load.main.title = 'Account Status';
                    } else {
                        service.load.main.accounts = response.data.user_subscriptions;
                        service.load.main.allowedDetections = response.data.allowed_detections;
                        service.load.main.billingPeriod = response.data.billing_period;
                        service.load.main.defaults = response.data.defaults;
                        service.load.main.documentation = response.data.documentation;
                        service.load.main.onlinePurchaseAllowed = response.data.online_purchace;
                        service.load.main.millionsDetections = response.data.millions_detections;
                        service.load.main.percentDetections = response.data.usage_pct;
                        if (service.load.main.percentDetections <= 60) {
                            service.load.main.percentClass = "progress-bar-success";
                        } else if (service.load.main.percentDetections <= 85) {
                            service.load.main.percentClass = "progress-bar-warning";
                        } else {
                            service.load.main.percentClass = "progress-bar-danger";
                        }
                        service.load.main.percentStyle = "width: " + service.load.main.percentDetections + "%;";
                        service.load.main.plan = response.data.plan;
                        service.load.main.subscription = response.data.subscription;
                        service.load.main.totalDetections = response.data.total_detections;

                        service.load.main.domains = response.data.domains;
                    }
                    service.load.main.user = response.data.user;
                    return true;
                }).catch(function (error) {
                    service.mainError.status = error.status;
                    if (error.status != 404) {
                        service.mainError.message = error.data.message;
                    }
                    return false;
                });
            } else if (args.main && !_.isUndefined(args.params.id)) {
                return Promise.resolve(true);
            } else {
                switch (args.name) {
                    case 'wurfljs.home.summary':
                        service.load.summary = {};
                        return wurfljsGoService.getUsage(args.params.id).then(function (response) {
                            service.load.summary.accountDetectionLimit = response.account_detection_limit;
                            service.load.summary.accountDetectionPrediction = response.account_detection_prediction;
                            service.load.summary.accountDetectionPredictionMillion = (service.load.summary.accountDetectionPrediction / 1000000);
                            if (service.load.summary.accountDetectionPrediction >= service.load.summary.accountDetectionLimit) {
                                service.load.summary.predictionStyle = "color: red;"
                            } else {
                                service.load.summary.predictionStyle = "color: green;"
                            }
                            service.load.summary.cnames = response.cnames;
                            service.load.summary.defaults = response.defaults;
                            service.load.summary.domains = response.domains;
                            service.load.summary.onlinePurchaseAllowed = response.online_purchase;

                            service.load.summary.periods = response.periods;
                            service.load.summary.plan = response.plan;
                            service.load.summary.selectedPeriod = _.first(service.load.summary.periods);
                            angular.forEach(service.load.summary.periods, function (val, key) {
                                if (_.isNull(service.load.summary.selectedPeriod) || _.isUndefined(service.load.summary.selectedPeriod)) {
                                    service.load.summary.selectedPeriod = key;
                                    service.load.summary.lastPeriod = key;
                                }
                            });

                            service.load.summary.chart = {
                                type: 'Gauge',
                                options: {
                                    minorTicks: 5
                                }
                            };
                            service.load.summary.chart.options.yellowFrom = (response.allowed_detections * .60);
                            service.load.summary.chart.options.yellowTo = (response.allowed_detections * .85);
                            service.load.summary.chart.options.redFrom = (response.allowed_detections * .85);
                            service.load.summary.chart.options.redTo = response.allowed_detections;
                            service.load.summary.chart.options.max = response.allowed_detections;
                            service.load.summary.chart.data = [
                                [
                                    'Label', 'Value'
                                ],
                                [
                                    'Detections', (response.account_detection_usage)
                                ]
                            ];
                            service.load.summary.dataExists = true;
                            service.load.summary.usageInPeriod = response.detection_count_fake;
                            return true;
                        }).catch(function (error) {
                            service.error.message = "Unable to determine detections";
                            console.log(error);
                            return false;
                        });
                    case 'wurfljs.home.gettingstarted':
                        return Promise.resolve(true);
                    case 'wurfljs.home.capabilities':
                        service.load.capabilities = {};
                        return wurfljsService.getCapabilities().then(function (response) {
                            service.load.capabilities.capabilities = response.data.capabilities;
                            return true;
                        }).catch(function (error) {
                            console.log(error);
                            service.error.status = error.status;
                            service.error.message = "Unable to gather capabilities";
                            service.error.data = error.data.data;
                            return false;
                        });
                    case 'wurfljs.home.domains':
                        service.load.domains = {};
                        return wurfljsService.getDomains(args.params.id).then(function (response) {
                            service.load.domains.defaults = response.data.defaults;
                            service.load.domains.domains = response.data.domains;
                            service.load.domains.notTrashed = response.data.notTrashed;
                            service.load.domains.plan = response.data.plan;
                            service.load.domains.trashed = response.data.trashed;
                            service.load.domains.whitelist = response.data.whitelist;
                            return true;
                        }).catch(function (error) {
                            console.log(error);
                            service.error.status = error.status;
                            service.error.message = "Unable to gather domains";
                            service.error.data = error.data.data;
                            return false;
                        });
                    case 'wurfljs.home.cnames':
                        service.load.cnames = {};
                        return wurfljsService.getCnames(args.params.id).then(function (response) {
                            service.load.cnames.cnames = response.data.cnames;
                            service.load.cnames.defaults = response.data.defaults;
                            service.load.cnames.plan = response.data.plan;
                            return true;
                        }).catch(function (error) {
                            console.log(error);
                            service.error.status = error.status;
                            service.error.message = "Unable to gather cnames";
                            service.error.data = error.data.data;
                            return false;
                        });
                    case 'wurfljs.home.billinghistory':
                        service.load.billinghistory = {};
                        return wurfljsGoService.getBillingHistory(args.params.id).then(function (response) {
                            let transactions = [...response.transactions];
                            transactions.forEach(t => {
                                const date = getDateFromSQLTimestamp(t.date);
                                t.date = new Intl.DateTimeFormat('en-CA').format(date);
                            });

                            service.load.billinghistory.transactions = transactions;
                            return true;
                        }).catch(function (error) {
                            console.log(error);
                            if (error.status != 403) {
                                service.error.message = error.message;
                            }
                            return false;
                        });
                    case 'wurfljs.home.updatebilling':
                        $ocLazyLoad.load({
                            files: [
                                'https://js.braintreegateway.com/web/dropin/1.9.3/js/dropin.min.js',
                                CHARGIFY_JS_URL
                            ]
                        });

                        service.load.updatebilling = {};
                        return wurfljsGoService.getBilling(args.params.id).then(function (response) {
                            service.load.updatebilling.billing_system = response.billing_system;
                            service.load.updatebilling.form = response.form;
                            service.load.updatebilling.form.country = angular.copy(service.load.updatebilling.form.country_id);
                            if (_.isNull(service.load.updatebilling.form.email)) {
                                service.load.updatebilling.form.email = response.user.email;
                            }
                            return homeService.getCountries('country_id,name,code').then(function (response) {
                                service.load.updatebilling.countries = response.data;
                                return true;
                            }).catch(function (error) {
                                console.log(error);
                                service.error.status = error.status;
                                service.error.message = error.message;
                                service.error.data = error.data;
                                return false;
                            });
                        }).catch(function (error) {
                            console.log(error);
                            if (error.status != 403) {
                                service.error.message = "An error occurred while gathering billing information";
                            }
                            return false;
                        });
                    case 'wurfljs.home.planinfo':
                        service.load.planinfo = {};
                        return wurfljsGoService.getPlanInfo(args.params.id).then(function (response) {
                            service.load.planinfo.changeDirection = response.change_direction;
                            service.load.planinfo.plans = [];
                            angular.forEach(response.plans, function (val, key) {
                                service.load.planinfo.plans[val.key] = val;
                            });
                            service.load.planinfo.subscription = response.subscription;
                            return true;
                        }).catch(function (error) {
                            console.log(error);
                            service.error.message = "An error occurred while gathering plan information";
                            return false;
                        });
                    case 'wurfljs.home.changeplan':
                        service.load.changeplan = {};
                        return wurfljsGoService.getChangePlan(args.params.id, args.params.plan).then(function (response) {
                            service.load.changeplan.currentPlan = response.current_plan;
                            service.load.changeplan.defaults = response.defaults;
                            service.load.changeplan.differences = response.differences;
                            service.load.changeplan.isUpgrade = response.is_upgrade;
                            service.load.changeplan.newPlan = response.new_plan;
                            service.load.changeplan.subscription = response.subscription;
                            return true;
                        }).catch(function (error) {
                            console.log(error);
                            if (error.status != 403) {
                                service.error.message = "An error occurred while gathering billing information";
                            } else {
                                service.error.message = error.message;
                            }
                            return false;
                        });
                    case 'wurfljs.home.increasedetections':
                        service.load.increasedetections = {};
                        return wurfljsService.getIncreaseDetections(args.params.id).then(function (response) {
                            service.load.increasedetections.spinnerOptions = {
                                decimals: 1,
                                verticalButtons: true,
                                mousewheel: false,
                                inputReadOnly: true,
                                max: 30
                            };
                            service.load.increasedetections.newNumber = response.data.stats.current / 1000000;
                            service.load.increasedetections.nextBilling = response.data.next_billing;
                            service.load.increasedetections.spinnerOptions.min = response.data.stats.min;
                            service.load.increasedetections.stats = response.data.stats;
                            return true;
                        }).catch(function (error) {
                            console.log(error);
                            service.error.status = error.status;
                            service.error.message = "An error occurred while gathering information on detections";
                            service.error.data = error.data.data;
                            return false;
                        });
                    case 'wurfljs.home.billinghistory.transaction':
                        service.load.transaction = {};
                        return wurfljsGoService.getTransactionReceipt(args.params.id, args.params.transactionId).then(function (response) {
                            service.load.transaction.email = response.email;
                            service.load.transaction.billingPeriod = {
                                start: null,
                                end: null
                            };
                            if (response['subscription'] && response['subscription']['billing_period_start_date']) {
                                const bp_start = getDateFromSQLTimestamp(response['subscription']['billing_period_start_date']);
                                const bp_end = getDateFromSQLTimestamp(response['subscription']['billing_period_end_date']);
                                service.load.transaction.billingPeriod.start = new Intl.DateTimeFormat('en-CA').format(bp_start);
                                service.load.transaction.billingPeriod.end = new Intl.DateTimeFormat('en-CA').format(bp_end);
                            }
                            const paymentDate = getDateFromSQLTimestamp(response.transaction['date']);
                            service.load.transaction.paymentDate = new Intl.DateTimeFormat('en-GB', { month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: '2-digit', second: '2-digit', hour12: false }).format(paymentDate);
                            service.load.transaction.plan = response.plan;
                            service.load.transaction.billing_details = response.billing_details;
                            service.load.transaction.subscription = response.subscription;
                            service.load.transaction.transaction = response.transaction;
                            return true;
                        }).catch(function (error) {
                            console.log(error);
                            service.error.message = error.message;
                            return false;
                        });
                    case 'wurfljs.signup':
                        service.load.signup = {
                            class: {
                                basic: 'plans-btn-word-width',
                                standard: 'plans-btn-word-width',
                                pro: 'plans-btn-word-width'
                            },
                            form: {
                                accountName: null,
                                address: null,
                                city: null,
                                country: null,
                                firstName: null,
                                lastName: null,
                                paymentPlan: null,
                                state: null,
                                zip: null
                            },
                            plans: {}
                        };
                        $ocLazyLoad.load(
                            'https://js.braintreegateway.com/web/dropin/1.9.3/js/dropin.min.js'
                        );

                        return wurfljsService.getPlans().then(function (response) {
                            for (const [key, value] of Object.entries(response.data.plans))
                                service.load.signup.plans[value.key] = value;

                            service.load.signup.selectedPlan = null;

                            switch (args.params.plan) {
                                case 'basic':
                                    service.load.signup.selectedPlan = service.load.signup.plans.WURFL_JS_BUSINESS_SMALL;
                                    service.load.signup.form.paymentPlan = 'WURFL_JS_BUSINESS_SMALL';
                                    service.load.signup.class.basic = 'plans-btn-selected-word-width';
                                    break;
                                case 'pro':
                                    service.load.signup.selectedPlan = service.load.signup.plans.WURFL_JS_BUSINESS_LARGE;
                                    service.load.signup.form.paymentPlan = 'WURFL_JS_BUSINESS_LARGE';
                                    service.load.signup.class.pro = 'plans-btn-selected-word-width';
                                    break;
                                case 'standard':
                                default:
                                    service.load.signup.selectedPlan = service.load.signup.plans.WURFL_JS_BUSINESS_MEDIUM;
                                    service.load.signup.form.paymentPlan = 'WURFL_JS_BUSINESS_MEDIUM';
                                    service.load.signup.class.standard = 'plans-btn-selected-word-width';
                                    break;
                            }

                            service.load.signup.form.paymentPlan = service.load.signup.selectedPlan.key;
                            return homeService.getCountries().then(function (response) {
                                service.load.signup.countries = response.data;
                                return true;
                            }).catch(function (error) {
                                console.log(error.data);
                                service.error.status = error.status;
                                service.error.message = error.data.message;
                                service.error.data = error.data.data;
                                return false;
                            });
                        }).catch(function (error) {
                            console.log(error);
                            service.error.status = error.status;
                            service.error.message = "An error occurred while gathering WURFL Cloud plans";
                            service.error.data = error.data.data;
                        });
                }
            }
        }

        function getDateFromSQLTimestamp(dateStr) {
            const [day, month, year] = dateStr.split(" ")[0].split("/");
            const time = dateStr.split(" ")[1];
            return new Date(`${year}-${month}-${day}T${time}`);
        }
    }
})();
