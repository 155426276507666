(function () {
    'use strict';

    angular.module('customerVault')
        .factory('wurfljsGoService', wurfljsGoService);

    wurfljsGoService.$inject = ['$resource', 'WURFL_ADMIN_MICROSERVICE_URL'];

    function wurfljsGoService($resource, WURFL_ADMIN_MICROSERVICE_URL) {
        var service = {
            resourceUrl: WURFL_ADMIN_MICROSERVICE_URL + '/v2',
            getBillingHistory: getBillingHistory,
            getBilling: getBilling,
            getBillingHistory: getBillingHistory,
            getChangePlan: getChangePlan,
            getPlanInfo: getPlanInfo,
            getPlans: getPlans,
            getTransactionReceipt: getTransactionReceipt,
            getUsage: getUsage,
            postCancelAccount: postCancelAccount,
            postChangePlan: postChangePlan,
            postSendTransactionReceipt: postSendTransactionReceipt,
            postUpdateBilling: postUpdateBilling,
            validateBilling: validateBilling
        };

        var resource = new $resource(service.resourceUrl + "/:product/:entity/:id/:action/:action_id", { id: '@id' }, {
            getBillingHistory: {
                headers: { 'x-smauth': 'true' },
                method: 'GET',
                params: {
                    product: null,
                    entity: 'subscriptions',
                    action: 'billinghistory',
                }
            },
            getBilling: {
                headers: { 'x-smauth': 'true' },
                method: 'GET',
                params: {
                    product: null,
                    entity: 'subscriptions',
                    action: 'billing',
                }
            },
            getChangePlan: {
                headers: { 'x-smauth': 'true' },
                method: 'GET',
                params: {
                    product: 'wurfljs',
                    entity: 'subscriptions',
                    action: 'plan'
                }
            },
            getPlanInfo: {
                headers: { 'x-smauth': 'true' },
                method: 'GET',
                params: {
                    product: 'wurfljs',
                    entity: 'subscriptions',
                    action: 'plans'
                }
            },
            getPlans: {
                headers: { 'x-smauth': 'true' },
                method: 'GET',
                params: {
                    product: 'wurfljs',
                    entity: 'subscriptions',
                    action: 'plans'
                }
            },
            getTransactionReceipt: {
                headers: { 'x-smauth': 'true' },
                method: 'GET',
                params: {
                    product: null,
                    entity: 'subscriptions',
                    action: 'transactions'
                }
            },
            getUsage: {
                headers: { 'x-smauth': 'true' },
                method: 'GET',
                params: {
                    product: 'wurfljs',
                    entity: 'subscriptions',
                    action: 'usage'
                }
            },
            postCancelAccount: {
                headers: { 'x-smauth': 'true' },
                method: 'POST',
                params: {
                    product: null,
                    entity: 'subscriptions',
                    action: 'cancel'
                }
            },
            postChangePlan: {
                headers: { 'x-smauth': 'true' },
                method: 'POST',
                params: {
                    product: 'wurfljs',
                    entity: 'subscriptions',
                    action: 'plan'
                }
            },
            postSendTransactionReceipt: {
                headers: { 'x-smauth': 'true' },
                method: 'POST',
                params: {
                    product: null,
                    entity: 'subscriptions',
                    action: 'transactions'
                }
            },
            postUpdateBilling: {
                headers: { 'x-smauth': 'true' },
                method: 'POST',
                params: {
                    product: null,
                    entity: 'subscriptions',
                    action: 'billing',
                    action_id: null
                }
            }
        });

        return service;

        function getBillingHistory(subscriptionId) {
            return resource.getBillingHistory({ id: subscriptionId }).$promise;
        }

        function getBilling(id) {
            return resource.getBilling({ id: id }).$promise;
        }

        function getChangePlan(id, plan) {
            return resource.getChangePlan({ id: id, action_id: plan }).$promise;
        }

        function getPlanInfo(id) {
            return resource.getPlanInfo({ id: id }).$promise;
        }

        function getPlans() {
            return resource.getPlans({ id: null }).$promise;
        }

        function getUsage(id) {
            return resource.getUsage({ id: id }).$promise;
        }

        function getTransactionReceipt(id, transactionId) {
            return resource.getTransactionReceipt({ id: id, action_id: transactionId }).$promise;
        }

        function postCancelAccount(id, reason) {
            return resource.postCancelAccount({ id: id }, { reason: reason }).$promise;
        }

        function postChangePlan(id, to, plan) {
            return resource.postChangePlan({ id: id, action_id: to }, { plan: plan }).$promise;
        }

        function postSendTransactionReceipt(id, transactionId, email) {
            return resource.postSendTransactionReceipt({ id: id, action_id: transactionId }, { email: email }).$promise;
        }

        function postUpdateBilling(id, form) {
            return resource.postUpdateBilling({ id: id }, form).$promise;
        }

        function validateBilling(form, payment) {
            var fields = ['address', 'city', 'state', 'zip', 'country'];
            if (payment) {
                fields.push('nonce');
            }

            angular.forEach(fields, function (val, key) {
                if (!_.isNull(form[val]) && form[val] != '') {
                    fields = _.reject(fields, function (v) {
                        return v == val;
                    });
                }
            });

            return fields;
        }
    }
})();
