(function () {
    'use strict';

    angular.module('customerVault.wurfljs')
        .controller('WurfljsBillingHistoryController', WurfljsBillingHistoryController);

    WurfljsBillingHistoryController.$inject = ['$state', '$stateParams', '$uibModal', 'bsLoadingOverlayService', 'Notification', 'wurfljsActions', 'wurfljsResolve', 'wurfljsService'];

    function WurfljsBillingHistoryController($state, $stateParams, $uibModal, bsLoadingOverlayService, Notification, wurfljsActions, wurfljsResolve, wurfljsService) {
        var vm = this;

        runLoad();

        function runLoad() {
            var resolveData = wurfljsResolve.load.billinghistory;
            var error = wurfljsResolve.error;

            vm.error = false;
            vm.openReceipt = openReceipt;
            vm.pageErrorView = null;
            vm.pageErrorViewMessage = null;
            vm.transactions = resolveData.transactions;

            vm.transactions = vm.transactions.map(transaction => ({
                ...transaction,
                status: transaction.status.toLowerCase(),
                rendered_billing_system: `${transaction.billing_system.charAt(0).toUpperCase()}${transaction.billing_system.substr(1)}`
            }));

            if (!_.isEmpty(error)) {
                vm.error = error.status;
                switch (error.status) {
                    case 403:
                        vm.pageErrorViewMessage = error.message;
                        break;
                    default:
                        vm.pageErrorView = 'view/errors.400';
                }
            }
        }

        function openReceipt(transactionId) {
            bsLoadingOverlayService.start({
                referenceId: 'wurfljs-billing-history'
            });
            wurfljsActions.openReceipt(transactionId);
        }
    }
})();
