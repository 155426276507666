(function() {
    'use strict';

    angular.module('customerVault.license')
        .controller('LicenseController', LicenseController);

    LicenseController.$inject = ['$rootScope', '$scope', '$state', '$stateParams', '$timeout', '$uibModal', '$window', 'breadcrumbsService', 'bsLoadingOverlayService', 'licenseAction', 'licenseResolve', 'licenseService', 'loaderService', 'Notification'];

    function LicenseController($rootScope, $scope, $state, $stateParams, $timeout, $uibModal, $window, breadcrumbsService, bsLoadingOverlayService, licenseAction, licenseResolve, licenseService, loaderService, Notification) {
        var vm = this;
        var resolveData = null;
        var error = null;

        run();

        function run() {
            resolveData = licenseResolve.load.main;
            error = licenseResolve.mainError;
            if (!_.isEmpty(error)) {
                switch (error.status) {
                    case 303:
                        $state.go('insight.home', {userId: error.data.user_id, id: $stateParams.id});
                        break;
                    case 404:
                        vm.pageErrorView = 'view/errors.404';
                        vm.error = error.status;
                        break;
                    case 403:
                        vm.pageErrorViewMessage = error.message;
                        vm.error = error.status;
                        break;
                    default:
                        vm.error = error.status;
                        vm.pageErrorView = 'view/errors.400';
                }
                return;
            } else {
                vm.allowedCapabilities = resolveData.allowedCapabilities;
                vm.capabilitiesTab = 'view/license.subpages.capabilities';
                vm.changeTab = changeTab;
                vm.config = resolveData.config;
                vm.createDownloadLink = createDownloadLink;
                vm.daysToExpire = resolveData.daysToExpire;
                vm.documentationUrl = resolveData.documentationUrl;
                vm.download = download;
                vm.downloadEvalText = downloadEvalText;
                vm.downloading = false;
                vm.downloadKey = resolveData.downloadKey;
                vm.error = false;
                vm.fileManagementAdmin = resolveData.fileManagementAdmin;
                vm.filexCustomerUrl = resolveData.filexCustomerUrl;
                vm.filexToken = resolveData.filexToken;
                vm.id = $stateParams.id;
                vm.inUse = resolveData.inUse;
                vm.isDataOnly = resolveData.isDataOnly;
                vm.isEvaluation = resolveData.isEvaluation;
                vm.isExpired = resolveData.isExpired;
                vm.isInfuze = resolveData.isInfuze;
                vm.isMicroservice = resolveData.isMicroservice;
                vm.pageErrorView = 'view/errors.400';
                vm.product = resolveData.product;
                vm.productLabel = resolveData.productLabel;
                vm.requiredCaps = resolveData.requiredCaps;
                vm.steps = licenseAction.getSteps(resolveData);
                vm.supportUrl = "https://support.scientiamobile.com";
                vm.title = resolveData.product.product_name;
                vm.topClasses = resolveData.topClasses;
                vm.viewArchives = viewArchives;
                vm.viewTab = licenseAction.getTab(resolveData);
                vm.user = resolveData.user;
            }
        }

        $rootScope.$on('reloadLicenseBreadcrumbs', function() {
            if (!_.isEmpty(error)) {
                licenseResolve.mainError = {};
                run();
            } else {
                vm.steps = null;
                $timeout(function() {
                    vm.steps = licenseAction.getSteps(resolveData);
                }, 100);
            }
        });

        $rootScope.$on('$stateChangeSuccess', function() {
            bsLoadingOverlayService.stop({
                referenceId: 'license-tabs'
            });
        });

        function changeTab(tab, view) {
            if (vm.viewTab != tab) {
                loaderService.show = false;
                bsLoadingOverlayService.start({
                    referenceId: 'license-tabs'
                });
                if (tab === 'gettingStarted' && vm.isDataOnly) {
                    vm.viewTab = 'capabilities';
                } else {
                    vm.viewTab = tab;
                }
                $state.go('license.'+view, $stateParams);
            }
        }

        function createDownloadLink() {
            bsLoadingOverlayService.start({
                referenceId: 'license-top'
            });
            licenseService.postCreateDownloadLink($stateParams.id).then(function(response) {
                vm.downloadKey = response.data.hash;
            }).catch(function(error) {
                console.log(error);
                Notification.error("Unable to create a download link");
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'license-top'
                });
            });
        }

        function download(url) {
            licenseAction.download(url, vm);
        }

        function downloadEvalText() {
            var currentTab = angular.copy(vm.viewTab);
            vm.viewTab = 'license';
            bsLoadingOverlayService.start({
                referenceId: 'license-tabs'
            });
            licenseService.getCreateEvaluationLicense().then(function(response) {
                licenseAction.createEvalLicense(response);
            }).catch(function(error) {
                console.log(error);
                Notification.error('Unable to download license');
            }).finally(function() {
                vm.viewTab = currentTab;
                bsLoadingOverlayService.stop({
                    referenceId: 'license-tabs'
                });
            });
        }

        function viewArchives() {
            licenseAction.openViewArchives(vm.downloadKey, vm.config);
        }
    }
})();
