(function () {
    'use strict';

    angular.module('customerVault')
        .factory('quwurflService', quwurflService);

    quwurflService.$inject = ['$resource', 'WURFL_ADMIN_MICROSERVICE_URL'];

    function quwurflService($resource, WURFL_ADMIN_MICROSERVICE_URL) {
        var service = {
            resourceUrl: WURFL_ADMIN_MICROSERVICE_URL + '/v2',
            get: get,
            getIndex: getIndex,
            addDomain: addDomain,
            deleteDomain: deleteDomain,
            addKey: addKey,
            deleteKey: deleteKey,
            userSubscriptions: userSubscriptions,
        };

        var resource = new $resource(service.resourceUrl + "/:product/:entity/:id/:action/:action_id", { id: '@id' }, {
            userSubscriptions: {
                method: 'GET',
                isArray: true,
                params: {
                    product: 'quwurfl',
                    entity: 'users',
                    action: 'subscriptions',
                    action_id: null
                },
                headers: { 'x-smauth': 'true' }
            },
            get: {
                method: 'GET',
                params: {
                    product: 'quwurfl',
                    entity: 'subscriptions',
                    action: null,
                    action_id: null
                },
                headers: { 'x-smauth': 'true' }
            },
            addDomain: {
                headers: { 'x-smauth': 'true' },
                method: 'POST',
                params: {
                    product: 'quwurfl',
                    entity: 'subscriptions',
                    action: 'domains',
                    action_id: null
                },
                isArray: true
            },
            deleteDomain: {
                headers: { 'x-smauth': 'true' },
                method: 'DELETE',
                params: {
                    product: 'quwurfl',
                    entity: 'subscriptions',
                    action: 'domains',
                    action_id: null,
                }
            },
            addKey: {
                headers: { 'x-smauth': 'true' },
                method: 'POST',
                params: {
                    product: null,
                    entity: 'subscriptions',
                    action: 'apikeys',
                    action_id: null
                }
            },
            deleteKey: {
                headers: { 'x-smauth': 'true' },
                method: 'DELETE',
                params: {
                    product: null,
                    entity: 'subscriptions',
                    action: 'apikeys'
                }
            },
        });

        return service;

        function get(id, options) {
            var params = {};
            if (id) {
                params.id = id;
            }

            if (options) {
                params = _.extend(params, options);
            }
            return resource.get(params).$promise;
        }

        function getIndex(id, userId) {
            return resource.get({ id: id }).$promise;
        }

        function addDomain(subscriptionId, domain) {
            var payload = [domain];
            return resource.addDomain({ id: subscriptionId }, payload).$promise;
        }

        function deleteDomain(subscriptionId, domainId) {
            return resource.deleteDomain({ id: subscriptionId, 'did': domainId }).$promise;
        }

        // userSubscriptions returns the subscriptions for a specified user
        function userSubscriptions(userId) {
            return resource.userSubscriptions({ id: userId }).$promise;
        }

        function addKey(subscriptionId, keyName) {
            var payload = {
                'key_name': keyName,
            }
            return resource.addKey({ id: subscriptionId }, payload).$promise;
        }

        function deleteKey(subscriptionId, keyId) {
            return resource.deleteKey({ id: subscriptionId, 'action_id': keyId }).$promise;
        }
    }
})();
