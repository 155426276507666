(function () {
    'use strict';

    angular.module('customerVault.wurfljs')
        .controller('WurfljsBillingController', WurfljsBillingController);

    WurfljsBillingController.$inject = [
        '$ocLazyLoad',
        '$scope',
        '$state',
        '$stateParams',
        '$timeout',
        'bsLoadingOverlayService',
        'Notification',
        'paymentService',
        'wurfljsResolve',
        'wurfljsGoService',
        'CHARGIFY_PUBLIC_KEY',
        'CHARGIFY_SERVER_HOST'
    ];

    function WurfljsBillingController(
        $ocLazyLoad,
        $scope,
        $state,
        $stateParams,
        $timeout,
        bsLoadingOverlayService,
        Notification,
        paymentService,
        wurfljsResolve,
        wurfljsGoService,
        CHARGIFY_PUBLIC_KEY,
        CHARGIFY_SERVER_HOST
    ) {
        var vm = this;
        var error = wurfljsResolve.error;
        const chargify = new window.Chargify();

        vm.billing_system = "braintree";
        vm.countries = [];
        vm.creditcard = null;
        vm.error = false;
        vm.errors = {
            address: false,
            city: false,
            state: false,
            zip: false,
            country: false
        };
        vm.form = null;
        vm.pageErrorView = null;
        vm.pageErrorViewMessage = null;
        vm.saving = false;
        vm.submit = submit;
        vm.updateCard = false;
        vm.chargify_country_code = "";

        vm.convertCountryCodeForChargify = (code) => {
            if (!code) code = vm.form.country;

            if (code.length > 3) {
                code = vm.countryNameToTwoLetterCode(code);
                vm.chargify_country_code = countryCodeMapFromTwoLettersToThreeLetters[code];
            }
            else if (code.length === 3) {
                vm.chargify_country_code = code;
            }

            return vm.chargify_country_code;
        };

        vm.twoLettersCountryCodeToName = (country) => {
            if (country.length == 3) country = countryCodeMapFromThreeLettersToTwoLetters[country];

            for (const c of vm.countries) {
                if (c.code == country) {
                    return c.name;
                }
            }

            return vm.countries[0].name;
        };

        vm.countryNameToTwoLetterCode = (country) => {
            for (const c of vm.countries) {
                if (c.name == country) {
                    return c.code;
                }
            }

            return vm.countries[0].code;
        };

        runLoad();

        function runLoad() {
            var resolveData = wurfljsResolve.load.updatebilling;

            setError();

            vm.countries = resolveData.countries;
            vm.billing_system = resolveData.billing_system;
            vm.creditcard = true;
            vm.form = resolveData.form;
            vm.form.card_type = vm.form.card_type.charAt(0).toUpperCase() + vm.form.card_type.slice(1);
            vm.form.masked_number = vm.form.masked_number.replace(/^.*?(\d{4})$/g, '******$1 ');

            vm.convertCountryCodeForChargify(resolveData.form.country);

            if (resolveData.billing_system == "maxio") setChargify();
        }

        function setError() {
            if (!_.isEmpty(error)) {
                vm.error = error.status;
                switch (error.status) {
                    case 403:
                        vm.pageErrorViewMessage = error.message;
                        break;
                    default:
                        vm.pageErrorView = 'view/errors.400';
                }
                return;
            }
        }

        function setChargify() {
            const load_params = {
                publicKey: CHARGIFY_PUBLIC_KEY,
                serverHost: CHARGIFY_SERVER_HOST,
                type: 'card',
                hideCardImage: true,
                optionalLabel: ' ',
                requiredLabel: '*',
                style: {
                    field: {
                        paddingRight: '5px',
                        height: '55px',
                        width: '100%',
                    },
                    input: {
                        borderRadius: '.25rem',
                        fontSize: '16px',
                        padding: '0.625rem 0.75rem',
                        height: '40px',
                        width: '100%',
                    },
                    label: {
                        display: 'none',
                    },
                },
                fields: {
                    firstName: {
                        selector: '#first_name',
                        placeholder: 'First Name on Card',
                        message: 'Invalid First Name',
                        required: true,
                    },
                    lastName: {
                        selector: '#last_name',
                        placeholder: 'Last Name on Card',
                        message: 'Invalid Last Name',
                        required: true,
                    },
                    number: {
                        selector: '#cc_number',
                        placeholder: 'Enter Card Number',
                        message: 'Invalid Card',
                        required: true,
                    },
                    month: {
                        selector: '#cc_month',
                        label: 'Month',
                        placeholder: 'MM',
                        message: 'Invalid Month',
                        required: true,
                    },
                    year: {
                        selector: '#cc_year',
                        label: 'Year',
                        placeholder: 'YYYY',
                        message: 'Invalid Year',
                        required: true,
                    },
                    cvv: {
                        selector: '#cc_cvv',
                        label: 'CVV',
                        placeholder: 'CVV',
                        message: 'Invalid CVV',
                        required: true,
                    },
                },
            };

            try {
                chargify.load(load_params);
            } catch (e) {
                console.error(e);
                vm.error = 500;
                vm.pageErrorViewMessage = e.message;
            }
        }

        var loadedInstance = null;

        if (_.isEmpty(error)) {
            generateToken();
        }

        $scope.$on('tokenGenerated', function (event, args) {
            braintree.dropin.create({
                authorization: args.token,
                container: '#wjs-billing-payment-form',
                card: {
                    cardholderName: {
                        required: true
                    }
                }
            }, function (createErr, instance) {
                $timeout(function () {
                    var disablePay = false;
                }, 1);
                loadedInstance = instance;
            });
        });

        function generateBraintreeToken() {
            return paymentService.getBraintreeAuthorization().then(function (response) {
                $scope.$broadcast('tokenGenerated', { token: response.data.authorization });
            });
        }

        async function generateChargifyToken() {
            const prom = new Promise((resolve, reject) => {
                chargify.token(
                    document.querySelector('#chargify-form'),
                    (chargifyToken) => {
                        resolve(chargifyToken);
                    },
                    (chargifyTokenErr) => {
                        console.log('Chargify token ERROR - error: ', chargifyTokenErr)
                        reject(chargifyTokenErr);
                    }
                );
            });

            return prom;
        }

        function generateToken() {
            if (vm.billing_system == 'braintree') {
                return generateBraintreeToken();
            }

            return null;
        }

        function runSubmit() {
            wurfljsGoService.postUpdateBilling($stateParams.id, vm.form).then(function (response) {
                Notification.success('Updated billing information!');
                if (!_.isNull(vm.form.nonce)) {
                    loadedInstance.teardown(function (err) {
                        if (!err) {
                            generateToken();
                            vm.creditcard = false;
                            wurfljsResolve.load.updatebilling = {};
                            wurfljsResolve.run({ name: $state.current.name, params: $stateParams }).then(function (response) {
                                if (response) {
                                    runLoad();
                                } else {
                                    error = wurfljsResolve.error;
                                    setError();
                                }
                            });
                        }
                    });
                }
            }).catch(function (error) {
                console.log(error);
                Notification.error(error.data.message);
                if (!_.isNull(vm.form.nonce)) {
                    loadedInstance.teardown(function (err) {
                        if (!err) {
                            generateToken();
                            vm.creditcard = false;
                            wurfljsResolve.load.updatebilling = {};
                            wurfljsResolve.run({ name: $state.current.name, params: $stateParams }).then(function (response) {
                                if (response) {
                                    runLoad();
                                } else {
                                    error = wurfljsResolve.error;
                                    setError();
                                }
                            });
                        }
                    });
                }
            }).finally(function () {
                bsLoadingOverlayService.stop({
                    referenceId: 'wjs-billing-form'
                });
            });
        }

        async function submit() {
            bsLoadingOverlayService.start({
                referenceId: 'wjs-billing-form'
            });

            vm.errors = {
                address: false,
                city: false,
                state: false,
                zip: false,
                country: false
            };

            vm.form.country_id = angular.copy(vm.form.country);

            if (vm.updateCard) {
                var errors = wurfljsGoService.validateBilling(vm.form, false);
                if (errors.length != 0) {
                    angular.forEach(errors, function (val) {
                        vm.errors[val] = true;
                    });
                    return;
                }

                switch (vm.billing_system) {
                    case "braintree":
                        loadedInstance.requestPaymentMethod(function (requestPaymentMethodErr, payload) {
                            if (!_.isUndefined(payload)) {
                                vm.form.nonce = payload.nonce;
                                vm.form.country_id = angular.copy(vm.form.country);
                                runSubmit();
                            }
                        });
                        return;
                    case 'maxio':
                        try {
                            vm.form.nonce = await generateChargifyToken();
                        } catch (err) {
                            console.error(err);
                            bsLoadingOverlayService.stop({
                                referenceId: 'wjs-billing-form'
                            });

                            let err_msg = "";
                            if (err.errors) {
                                err_msg = (err.errors instanceof Array ? err.errors.join("<br/>") : err.errors)
                            } else if (err.message) {
                                err_msg = err.message;
                                if (err.invalidFields) err_msg = err_msg.substring(0, err_msg.length - 1) + ": " + err.invalidFields.join(", ");
                            } else {
                                err_msg = JSON.stringify(err);
                            }

                            Notification.error(err_msg);

                            return;
                        }

                        runSubmit();
                        return;
                    default:
                        const err = `Invalid billing system '${vm.billing_system}'`;
                        Notification.error(err);
                }
            } else {
                var errors = wurfljsGoService.validateBilling(vm.form, false);
                if (errors.length != 0) {
                    angular.forEach(errors, function (val) {
                        vm.errors[val] = true;
                    });
                    return;
                }
                runSubmit();
            }
        }
    }

    const countryCodeMapFromTwoLettersToThreeLetters = {
        'AB': 'ABW', 'AF': 'AFG', 'AO': 'AGO', 'AI': 'AIA', 'AX': 'ALA', 'AL': 'ALB', 'AD': 'AND', 'AE': 'ARE', 'AR': 'ARG', 'AM': 'ARM',
        'AS': 'ASM', 'AQ': 'ATA', 'TF': 'ATF', 'AG': 'ATG', 'AU': 'AUS', 'AT': 'AUT', 'AZ': 'AZE', 'BI': 'BDI', 'BE': 'BEL', 'BJ': 'BEN',
        'BQ': 'BES', 'BF': 'BFA', 'BD': 'BGD', 'BG': 'BGR', 'BH': 'BHR', 'BS': 'BHS', 'BA': 'BIH', 'BL': 'BLM', 'BY': 'BLR', 'BZ': 'BLZ',
        'BM': 'BMU', 'BO': 'BOL', 'BR': 'BRA', 'BB': 'BRB', 'BN': 'BRN', 'BT': 'BTN', 'BV': 'BVT', 'BW': 'BWA', 'CF': 'CAF', 'CA': 'CAN',
        'CC': 'CCK', 'CH': 'CHE', 'CL': 'CHL', 'CN': 'CHN', 'CI': 'CIV', 'CM': 'CMR', 'CD': 'COD', 'CG': 'COG', 'CK': 'COK', 'CO': 'COL',
        'KM': 'COM', 'CV': 'CPV', 'CR': 'CRI', 'CU': 'CUB', 'CW': 'CUW', 'CX': 'CXR', 'KY': 'CYM', 'CY': 'CYP', 'CZ': 'CZE', 'DE': 'DEU',
        'DJ': 'DJI', 'DM': 'DMA', 'DK': 'DNK', 'DO': 'DOM', 'DZ': 'DZA', 'EC': 'ECU', 'EG': 'EGY', 'ER': 'ERI', 'EH': 'ESH', 'ES': 'ESP',
        'EE': 'EST', 'ET': 'ETH', 'FI': 'FIN', 'FJ': 'FJI', 'FK': 'FLK', 'FR': 'FRA', 'FO': 'FRO', 'FM': 'FSM', 'GA': 'GAB', 'GB': 'GBR',
        'GE': 'GEO', 'GG': 'GGY', 'GH': 'GHA', 'GI': 'GIB', 'GN': 'GIN', 'GP': 'GLP', 'GM': 'GMB', 'GW': 'GNB', 'GQ': 'GNQ', 'GR': 'GRC',
        'GD': 'GRD', 'GL': 'GRL', 'GT': 'GTM', 'GF': 'GUF', 'GU': 'GUM', 'GY': 'GUY', 'HK': 'HKG', 'HM': 'HMD', 'HN': 'HND', 'HR': 'HRV',
        'HT': 'HTI', 'HU': 'HUN', 'ID': 'IDN', 'IM': 'IMN', 'IN': 'IND', 'IO': 'IOT', 'IE': 'IRL', 'IR': 'IRN', 'IQ': 'IRQ', 'IS': 'ISL',
        'IL': 'ISR', 'IT': 'ITA', 'JM': 'JAM', 'JE': 'JEY', 'JO': 'JOR', 'JP': 'JPN', 'KZ': 'KAZ', 'KE': 'KEN', 'KG': 'KGZ', 'KH': 'KHM',
        'KI': 'KIR', 'KN': 'KNA', 'KR': 'KOR', 'KW': 'KWT', 'LA': 'LAO', 'LB': 'LBN', 'LR': 'LBR', 'LY': 'LBY', 'LC': 'LCA', 'LI': 'LIE',
        'LK': 'LKA', 'LS': 'LSO', 'LT': 'LTU', 'LU': 'LUX', 'LV': 'LVA', 'MO': 'MAC', 'MF': 'MAF', 'MA': 'MAR', 'MC': 'MCO', 'MD': 'MDA',
        'MG': 'MDG', 'MV': 'MDV', 'MX': 'MEX', 'MH': 'MHL', 'MK': 'MKD', 'ML': 'MLI', 'MT': 'MLT', 'MM': 'MMR', 'ME': 'MNE', 'MN': 'MNG',
        'MP': 'MNP', 'MZ': 'MOZ', 'MR': 'MRT', 'MS': 'MSR', 'MQ': 'MTQ', 'MU': 'MUS', 'MW': 'MWI', 'MY': 'MYS', 'YT': 'MYT', 'NA': 'NAM',
        'NC': 'NCL', 'NE': 'NER', 'NF': 'NFK', 'NG': 'NGA', 'NI': 'NIC', 'NU': 'NIU', 'NL': 'NLD', 'NO': 'NOR', 'NP': 'NPL', 'NR': 'NRU',
        'NZ': 'NZL', 'OM': 'OMN', 'PK': 'PAK', 'PA': 'PAN', 'PN': 'PCN', 'PE': 'PER', 'PH': 'PHL', 'PW': 'PLW', 'PG': 'PNG', 'PL': 'POL',
        'PR': 'PRI', 'KP': 'PRK', 'PT': 'PRT', 'PY': 'PRY', 'PS': 'PSE', 'PF': 'PYF', 'QA': 'QAT', 'RE': 'REU', 'RO': 'ROU', 'RU': 'RUS',
        'RW': 'RWA', 'SA': 'SAU', 'SD': 'SDN', 'SN': 'SEN', 'SG': 'SGP', 'GS': 'SGS', 'SH': 'SHN', 'SJ': 'SJM', 'SB': 'SLB', 'SL': 'SLE',
        'SV': 'SLV', 'SM': 'SMR', 'SO': 'SOM', 'PM': 'SPM', 'RS': 'SRB', 'SS': 'SSD', 'ST': 'STP', 'SR': 'SUR', 'SK': 'SVK', 'SI': 'SVN',
        'SE': 'SWE', 'SZ': 'SWZ', 'SX': 'SXM', 'SC': 'SYC', 'SY': 'SYR', 'TC': 'TCA', 'TD': 'TCD', 'TG': 'TGO', 'TH': 'THA', 'TJ': 'TJK',
        'TK': 'TKL', 'TM': 'TKM', 'TL': 'TLS', 'TO': 'TON', 'TT': 'TTO', 'TN': 'TUN', 'TR': 'TUR', 'TV': 'TUV', 'TW': 'TWN', 'TZ': 'TZA',
        'UG': 'UGA', 'UA': 'UKR', 'UM': 'UMI', 'UY': 'URY', 'US': 'USA', 'UZ': 'UZB', 'VA': 'VAT', 'VC': 'VCT', 'VE': 'VEN', 'VG': 'VGB',
        'VI': 'VIR', 'VN': 'VNM', 'VU': 'VUT', 'WF': 'WLF', 'WS': 'WSM', 'XK': 'XXK', 'YE': 'YEM', 'ZA': 'ZAF', 'ZM': 'ZMB', 'ZW': 'ZWE'
    };

    const countryCodeMapFromThreeLettersToTwoLetters = {
        'ABW': 'AB', 'AFG': 'AF', 'AGO': 'AO', 'AIA': 'AI', 'ALA': 'AX', 'ALB': 'AL', 'AND': 'AD', 'ARE': 'AE', 'ARG': 'AR', 'ARM': 'AM',
        'ASM': 'AS', 'ATA': 'AQ', 'ATF': 'TF', 'ATG': 'AG', 'AUS': 'AU', 'AUT': 'AT', 'AZE': 'AZ', 'BDI': 'BI', 'BEL': 'BE', 'BEN': 'BJ',
        'BES': 'BQ', 'BFA': 'BF', 'BGD': 'BD', 'BGR': 'BG', 'BHR': 'BH', 'BHS': 'BS', 'BIH': 'BA', 'BLM': 'BL', 'BLR': 'BY', 'BLZ': 'BZ',
        'BMU': 'BM', 'BOL': 'BO', 'BRA': 'BR', 'BRB': 'BB', 'BRN': 'BN', 'BTN': 'BT', 'BVT': 'BV', 'BWA': 'BW', 'CAF': 'CF', 'CAN': 'CA',
        'CCK': 'CC', 'CHE': 'CH', 'CHL': 'CL', 'CHN': 'CN', 'CIV': 'CI', 'CMR': 'CM', 'COD': 'CD', 'COG': 'CG', 'COK': 'CK', 'COL': 'CO',
        'COM': 'KM', 'CPV': 'CV', 'CRI': 'CR', 'CUB': 'CU', 'CUW': 'CW', 'CXR': 'CX', 'CYM': 'KY', 'CYP': 'CY', 'CZE': 'CZ', 'DEU': 'DE',
        'DJI': 'DJ', 'DMA': 'DM', 'DNK': 'DK', 'DOM': 'DO', 'DZA': 'DZ', 'ECU': 'EC', 'EGY': 'EG', 'ERI': 'ER', 'ESH': 'EH', 'ESP': 'ES',
        'EST': 'EE', 'ETH': 'ET', 'FIN': 'FI', 'FJI': 'FJ', 'FLK': 'FK', 'FRA': 'FR', 'FRO': 'FO', 'FSM': 'FM', 'GAB': 'GA', 'GBR': 'GB',
        'GEO': 'GE', 'GGY': 'GG', 'GHA': 'GH', 'GIB': 'GI', 'GIN': 'GN', 'GLP': 'GP', 'GMB': 'GM', 'GNB': 'GW', 'GNQ': 'GQ', 'GRC': 'GR',
        'GRD': 'GD', 'GRL': 'GL', 'GTM': 'GT', 'GUF': 'GF', 'GUM': 'GU', 'GUY': 'GY', 'HKG': 'HK', 'HMD': 'HM', 'HND': 'HN', 'HRV': 'HR',
        'HTI': 'HT', 'HUN': 'HU', 'IDN': 'ID', 'IMN': 'IM', 'IND': 'IN', 'IOT': 'IO', 'IRL': 'IE', 'IRN': 'IR', 'IRQ': 'IQ', 'ISL': 'IS',
        'ISR': 'IL', 'ITA': 'IT', 'JAM': 'JM', 'JEY': 'JE', 'JOR': 'JO', 'JPN': 'JP', 'KAZ': 'KZ', 'KEN': 'KE', 'KGZ': 'KG', 'KHM': 'KH',
        'KIR': 'KI', 'KNA': 'KN', 'KOR': 'KR', 'KWT': 'KW', 'LAO': 'LA', 'LBN': 'LB', 'LBR': 'LR', 'LBY': 'LY', 'LCA': 'LC', 'LIE': 'LI',
        'LKA': 'LK', 'LSO': 'LS', 'LTU': 'LT', 'LUX': 'LU', 'LVA': 'LV', 'MAC': 'MO', 'MAF': 'MF', 'MAR': 'MA', 'MCO': 'MC', 'MDA': 'MD',
        'MDG': 'MG', 'MDV': 'MV', 'MEX': 'MX', 'MHL': 'MH', 'MKD': 'MK', 'MLI': 'ML', 'MLT': 'MT', 'MMR': 'MM', 'MNE': 'ME', 'MNG': 'MN',
        'MNP': 'MP', 'MOZ': 'MZ', 'MRT': 'MR', 'MSR': 'MS', 'MTQ': 'MQ', 'MUS': 'MU', 'MWI': 'MW', 'MYS': 'MY', 'MYT': 'YT', 'NAM': 'NA',
        'NCL': 'NC', 'NER': 'NE', 'NFK': 'NF', 'NGA': 'NG', 'NIC': 'NI', 'NIU': 'NU', 'NLD': 'NL', 'NOR': 'NO', 'NPL': 'NP', 'NRU': 'NR',
        'NZL': 'NZ', 'OMN': 'OM', 'PAK': 'PK', 'PAN': 'PA', 'PCN': 'PN', 'PER': 'PE', 'PHL': 'PH', 'PLW': 'PW', 'PNG': 'PG', 'POL': 'PL',
        'PRI': 'PR', 'PRK': 'KP', 'PRT': 'PT', 'PRY': 'PY', 'PSE': 'PS', 'PYF': 'PF', 'QAT': 'QA', 'REU': 'RE', 'ROU': 'RO', 'RUS': 'RU',
        'RWA': 'RW', 'SAU': 'SA', 'SDN': 'SD', 'SEN': 'SN', 'SGP': 'SG', 'SGS': 'GS', 'SHN': 'SH', 'SJM': 'SJ', 'SLB': 'SB', 'SLE': 'SL',
        'SLV': 'SV', 'SMR': 'SM', 'SOM': 'SO', 'SPM': 'PM', 'SRB': 'RS', 'SSD': 'SS', 'STP': 'ST', 'SUR': 'SR', 'SVK': 'SK', 'SVN': 'SI',
        'SWE': 'SE', 'SWZ': 'SZ', 'SXM': 'SX', 'SYC': 'SC', 'SYR': 'SY', 'TCA': 'TC', 'TCD': 'TD', 'TGO': 'TG', 'THA': 'TH', 'TJK': 'TJ',
        'TKL': 'TK', 'TKM': 'TM', 'TLS': 'TL', 'TON': 'TO', 'TTO': 'TT', 'TUN': 'TN', 'TUR': 'TR', 'TUV': 'TV', 'TWN': 'TW', 'TZA': 'TZ',
        'UGA': 'UG', 'UKR': 'UA', 'UMI': 'UM', 'URY': 'UY', 'USA': 'US', 'UZB': 'UZ', 'VAT': 'VA', 'VCT': 'VC', 'VEN': 'VE', 'VGB': 'VG',
        'VIR': 'VI', 'VNM': 'VN', 'VUT': 'VU', 'WLF': 'WF', 'WSM': 'WS', 'XXK': 'XK', 'YEM': 'YE', 'ZAF': 'ZA', 'ZMB': 'ZM', 'ZWE': 'ZW'
    };


})();
