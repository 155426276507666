(function () {
    'use strict';

    angular.module('customerVault.wurfljs')
        .controller('WurfljsChangePlanController', WurfljsChangePlanController);

    WurfljsChangePlanController.$inject = ['$rootScope', '$stateParams', 'bsLoadingOverlayService', 'Notification', 'wurfljsResolve', 'wurfljsGoService'];

    function WurfljsChangePlanController($rootScope, $stateParams, bsLoadingOverlayService, Notification, wurfljsResolve, wurfljsGoService) {
        var vm = this;
        var resolveData = wurfljsResolve.load.changeplan;
        var error = wurfljsResolve.error;

        vm.changePlan = changePlan;
        vm.changeTab = changeTab;
        vm.currentPlan = resolveData.currentPlan;
        vm.defaults = resolveData.defaults;
        vm.differences = resolveData.differences;
        vm.error = false;
        vm.id = $stateParams.id;
        vm.isUpgrade = resolveData.isUpgrade;
        vm.newPlan = resolveData.newPlan;
        vm.pageErrorView = null;
        vm.pageErrorViewMessage = null;
        vm.subscription = resolveData.subscription;

        if (!_.isEmpty(error)) {
            vm.error = error.status;
            switch (error.status) {
                case 403:
                    vm.pageErrorViewMessage = error.message;
                    break;
                default:
                    vm.pageErrorView = 'view/errors.400';
            }
        }

        function changePlan() {
            bsLoadingOverlayService.start({
                referenceId: 'wjs-change-plan-form'
            });
            wurfljsGoService.postChangePlan($stateParams.id, $stateParams.plan, vm.newPlan.key).then(function (response) {
                Notification.success('Your plan changed successfully!');
                changeTab('gettingstarted');
                $rootScope.$broadcast('reloadWurflJsAccount');
            }).catch(function (error) {
                console.log(error.data);
                if (error.tier) {
                    Notification.warning("Your plan changed successfully, however your support permissions were not updated. Please contact support@scientiamobile.com for assistance");
                    changeTab('gettingstarted');
                    $rootScope.$broadcast('reloadWurflJsAccount');
                } else {
                    Notification.error(error.message);
                }
            }).finally(function () {
                bsLoadingOverlayService.stop({
                    referenceId: 'wjs-change-plan-form'
                });
            });
        }

        function changeTab(tab, state) {
            $rootScope.$broadcast('wjsChangeTab', { tab: tab, state: state });
        }
    }
})();
