(function () {
    'use strict';

    angular.module('customerVault.wurfljs')
        .controller('WurfljsCancelConfirmController', WurfljsCancelConfirmController);

    WurfljsCancelConfirmController.$inject = ['$rootScope', '$stateParams', '$uibModalInstance', 'bsLoadingOverlayService', 'cancelReason', 'Notification', 'wurfljsGoService'];

    function WurfljsCancelConfirmController($rootScope, $stateParams, $uibModalInstance, bsLoadingOverlayService, cancelReason, Notification, wurfljsGoService) {
        var vm = this;

        vm.close = close;
        vm.proceed = proceed;

        function close() {
            $uibModalInstance.close();
        }

        function proceed() {
            bsLoadingOverlayService.start({
                referenceId: 'confirm-cancel-account'
            });

            wurfljsGoService.postCancelAccount($stateParams.id, cancelReason).then(function (response) {
                Notification.success(response.message);
                $uibModalInstance.dismiss();
                $rootScope.$broadcast('wurfljsCanceledAccount');
            }).catch(function (error) {
                console.log(error.data);
                Notification.error(error.data.message);
            }).finally(function () {
                bsLoadingOverlayService.stop({
                    referenceId: 'confirm-cancel-account'
                });
            });
        }
    }
})();
